<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">mdi-view-quilt</v-icon>

      <v-icon v-else>mdi-dots-vertical</v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$t($route.name)"
    />

    <v-spacer />

    <v-text-field
      :label="$t('search')"
      v-model="search"
      color="secondary"
      hide-details
      style="max-width: 165px"
      @keyup.native.enter="doSearch()"
    >
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small @click="doSearch()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <dashboard-core-settings />

    <v-menu
      v-if="totalNotifications"
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{ totalNotifications }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <v-list-item
          v-for="(n, i) in notifications"
          :key="`item-${i}`"
          @click="showNotifications(n)"
        >
          <v-list-item-icon
            ><v-icon>{{ n.icon }}</v-icon></v-list-item-icon
          >
          <v-list-item-title v-text="n.text" />
        </v-list-item>

        <v-list-item v-if="notifications" @click="showNotifications(0)">
          <v-list-item-title>{{
            $t("notifications.Show list")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <div class="mx-3" />
    <locale-changer />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" nav>
        <v-list-item @click="$router.push('/pages/user')">
          <v-list-item-title>{{ loggedInUser.name }} profile</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import LocaleChanger from "./LocaleChanger";
// Utilities
import { mapState, mapMutations } from "vuex";

import notifications from "mixins/notifications";
import DashboardCoreSettings from "./Settings";

export default {
  name: "DashboardCoreAppBar",
  components: {
    LocaleChanger,
    DashboardCoreSettings,
  },
  mixins: [notifications],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    accentColor: "#f00",
    languages: [
      {
        value: "en",
        text: "English",
      },
      {
        value: "de",
        text: "German",
      },
    ],
    getNotificationsTimeout: null,
    search: "",
  }),

  computed: {
    ...mapState(["drawer"]),
  },

  created() {
    this.refreshNotifications(true);
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    logout() {
      localStorage.removeItem("loggedInUser");
      this.post("/logout");
      this.$router.push("/login");
    },
    showNotifications(n) {
      if (n.link) {
        this.mark_read(n);
        this.$router.push(n.link);
      } else {
        this.$router.push("/pages/notifications");
      }
    },
    refreshNotifications(all = false) {
      let loggedInUser = localStorage.getItem("loggedInUser");
      if (loggedInUser == "null") {
        loggedInUser = null;
      }
      if (loggedInUser) {
        let since = all ? 0 : this.lastNotification;
        this.get("/profile/notifications/?since=" + since).then((data) => {
          this.getNotificationsTimeout = null;
          if (since) {
            this.notifyUser(data.latest);
            this.updateNotifications(data);
          } else {
            this.setNotifications(data);
          }
          if (!this.getNotificationsTimeout) {
            this.getNotificationsTimeout = setTimeout(
              this.refreshNotifications,
              1000
            );
          }
        });
      }
    },
    notifyUser(n) {
      if ("Notification" in window && Notification.permission === "granted") {
        for (let i in n) {
          let nt = this.process(n[i]);
          //console.dir(nt);
          var notification = new Notification(nt.text, {
            icon: "/img/FinLogo.png",
            tag: nt.tag,
          });
        }
      }
    },
    doSearch() {
      this.search = this.search.trim();
      if (this.search.length == 16) {
        if (
          this.$route.name == "tickets.edit" &&
          this.$route.params.uid == this.search
        ) {
          this.search = "";
          return;
        }
        this.$router.push("/pages/tickets/" + this.search);
        if (this.$route.name == "tickets.edit") {
          window.eventBus.$emit("changeTicket", this.search);
        }
        this.search = "";
      }
    },
  },
};
</script>

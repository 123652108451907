<template>
  <v-menu bottom left offset-y origin="top right" transition="scale-transition">
    <template v-slot:activator="{ on }">
      <v-chip pill v-on="on">{{ currentLocale }}</v-chip>
    </template>
    <v-list :tile="false" nav>
      <v-list-item
        v-for="(n, i) in languages"
        :key="`item-${i}`"
        @click="setLocale(n.value)"
      >
        <v-list-item-title v-text="n.text" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "LocaleChanger",
  data() {
    return {
      languages: [
        {
          value: "en",
          text: "English",
        },
        {
          value: "de",
          text: "German",
        },
      ],
    };
  },
  computed: {
    currentLocale() {
      for (const l in this.languages) {
        if (this.languages[l].value === this.$i18n.locale) {
          return this.$t(this.languages[l].text);
        }
      }
      return this.$i18n.locale;
    },
  },
  methods: {
    setLocale(locale) {
      let loggedInUser = localStorage.getItem("loggedInUser");
      if (loggedInUser == "null") {
        loggedInUser = null;
      }
      if (loggedInUser) {
        this.post("/locale", { locale: locale }).then((data) => {
          this.$i18n.locale = data.locale;
        });
      } else {
        this.$i18n.locale = locale;
      }
    },
  },
};
</script>
